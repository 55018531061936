#posts {
    padding: 5%;
}

.postsContainer {
    display: flex;
    flex-wrap: wrap;
}

.post {
    margin: auto;
    box-shadow: 5px 5px lightgrey;
    text-align: center;
    width: 30%;
    padding: 2%;
    border-radius: 1%;
    border-bottom: 5px solid #019267;
    margin-bottom: 5%;
    transition: transform .1s ease-in;
}

.post:hover {
    transform: scale(1.1);
}

.post img {
    width: 100%;
    height: 15vmax;
}

@media screen and (max-device-width: 580px) {
    .post {
        width: 40%;
    }
}
