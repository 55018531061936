#grants {
    width: 100%;
    overflow: hidden;
}

.submit_btn{
    width: 200px;
    height: 50px;
    background-color: transparent;
    border: 1px solid #3EC70B;
    color: #3EC70B;
    border-radius: 10px; 
    transition: transform .1s ease-in;
    margin: 5%;
    align-items: center;
}

.submit_btn:hover {
    transform: scale(1.2);
}