#footer {
    width: 100%;
    border-top: 2px solid grey;
    overflow: hidden;
}

.cookies {
  width: 100%;
  position: absolute;
  background-color: white;
  display: flex;
  z-index: 3;
}

.footerForm {
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 3;
}

.contactForm {
  width: 80%;
}

.contactClose {
  width: 20%;
}

.cookiesDesc {
    padding: 2%;
    width:80%;
}

.cookiesClose {
    width: 20%;
}

.close-container{
  float: right;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.leftright{
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #2C2E2F;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all .3s ease-in;
}

.rightleft{
  height: 4px;
  width: 50px;
  position: absolute;
  margin-top: 24px;
  background-color: #2C2E2F;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all .3s ease-in;
}

label{
  color: white;
  font-family: Helvetica, Arial, sans-serif; 
  font-size: .6em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .3s ease-in;
  opacity: 0;
}
.close{
  margin: 60px 0 0 5px;
  position: absolute;
}

.close-container:hover .leftright{
  transform: rotate(-45deg);
  background-color: red;
}
.close-container:hover .rightleft{
  transform: rotate(45deg);
  background-color: red;
}
.close-container:hover label{
  opacity: 1;
}
