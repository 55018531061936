#contact {
    border-top: 1px solid black;
}

.contactForm {
    display: block;
}

.contactFormSubmitButton {
    border-radius: 10px;
    background-color: #2C2E2F;
    color: white;
    border: none;
    box-shadow: 0 5px 0 #000000;
}

.contactFormSubmitButton:hover {
    transform: translateY(4px);
    box-shadow: 0 1px 0 #000000;
}