#header {
    position: sticky;
    top: 0;
    border-bottom: 2px solid grey;
    padding: 1%;
    z-index: 110;
    background-color: #FFFFFF;
}

.link:hover {
    cursor: pointer;
    transform: scale(1.2);
}

@media screen and (max-device-width: 580px) {
    #header h1 {
        font-size: 140%;
    }
}
