#donate {
    text-align: center;
    padding: 10%;
    min-height: 80vh;
    z-index: 1;
}

#donate button {
    border-radius: 10px;
    background-color: #2C2E2F;
    color: white;
    border: none;
    box-shadow: 0 5px 0 #000000;
    padding: 1%;
}

#donate button:hover {
    transform: translateY(4px);
    box-shadow: 0 1px 0 #000000;
}

#zoid-paypal-buttons-uid_7ea4257800_mjm6mju6ntu {
    margin: 3%;
}

@media screen and (max-device-width: 580px) {
    .donateInput{
        margin: 5%;
    }
    .donationButton{
        padding: 10%;
        margin: 5%;
    }
}