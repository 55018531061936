#donateButton {
    width: 30%;
    margin: auto;
    display: flex;
    padding: 5%;
    font-family: 'Oswald', sans-serif;
}

@media screen and (max-device-width: 580px) {
    #donateButton {
        width: 50%;
    }
}

#donateButton button {
    width: 100%;
    font-size: 180%;
    color: white;
    height: 50px;
    border-radius: 25px;
    border-color: transparent;
    background-color: #3EC70B;
    transition: background .2s ease-in;
    box-shadow: 0 5px 0 #257706;
}

#donateButton button:hover {
    background-color: #257706;
    transform: translateY(5px);
    box-shadow: none;
}